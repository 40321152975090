import './assets/main.scss'
import * as Sentry from '@sentry/vue'
import * as bootstrap from 'bootstrap'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router/Router'

import { msalPlugin } from './plugins/msalPlugin'
import { msalInstance } from './helpers/authConfig'
import { type AuthenticationResult, EventType } from '@azure/msal-browser'
import { CustomNavigationClient } from './router/NavigationClient'

import * as Spotlight from '@spotlightjs/spotlight'

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router)
msalInstance.setNavigationClient(navigationClient)

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

const app = createApp(App)

if (import.meta.env.VITE_USE_SENTRY === 'true') {
  Sentry.init({
    app,
    dsn: 'https://dc4ccd6fdc4619f7c8a52817da1e9529@sentry.it.chq.org/36',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    trackComponents: true,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0

    // beforeSend: (event) => {
    //   // Check if it is an exception, and if so, show the report dialog
    //   if (event.exception) {
    //     Sentry.showReportDialog({ eventId: event.event_id })
    //   }
    //   return event
    // }
  })
}

if (import.meta.env.VITE_USE_SPOTLIGHT === 'true') {
  Spotlight.init()
}

app.use(router)
app.use(msalPlugin, msalInstance)
app.use(createPinia())
router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount('#app')
})
