<template>
  <header class="_header_1jnrn_1 _static_1jnrn_12">
    <div class="_container_1jnrn_17">
      <div class="_brand_1jnrn_36">
        <a href="/">
          <img
            class="_logo_179rk_1"
            alt="logo"
            src="https://www.chq.org/wp-content/uploads/2023/04/chqassemblylogo.png"
            height="46"
            width="139"
          />
        </a>
      </div>
      <nav v-show="!isMobileScreen" class="_nav_1jnrn_41" aria-label="menu">
        <a
          class="_button_1h2k4_1 _default_1h2k4_42 _text_1h2k4_58 _active_1h2k4_53"
          href="https://assembly.chq.org/"
          aria-current="page"
        >
          <span>Return to Assembly</span>
        </a>
        <a
          class="_button_1h2k4_1 _default_1h2k4_42 _text_1h2k4_58"
          href="https://giving.chq.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Donate</span>
        </a>
        <a
          class="_button_1h2k4_1 _default_1h2k4_42 _text_1h2k4_58"
          href="https://chq.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>chq.org</span>
        </a>
      </nav>
      <nav v-show="isMobileScreen" class="_nav_1jnrn_41" style="display:flex;flex-direction: row-reverse;" aria-label="menu">
        <div class="_menu_1jnrn_24" style="display:block" @click="toggleDropdown">
          <div class="_iconButton_ynwnq_1 _iconButton_1jnrn_28" aria-label="Open menu" role="button" tabindex="0">
            <svg class="_icon_23h0x_1" viewBox="0 0 24 24" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
              <g><path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
              </g>
            </svg>
            <div v-show="isDropdownOpen" id="dropdownMobile" class="panel_form" style="z-index:9999; position: absolute; top:30px;right:0; width:200px; background: rgb(56, 56, 68);">
              <div class="w-100 centered-content">
                <a
                  class="_button_1h2k4_1 _default_1h2k4_42 _text_1h2k4_58 _active_1h2k4_53 w-100"
                  href="https://assembly.chq.org/"
                  aria-current="page"
                >
                  <span>Return to Assembly</span>
                </a>
                <a
                  class="_button_1h2k4_1 _default_1h2k4_42 _text_1h2k4_58 w-100"
                  href="https://giving.chq.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Donate</span>
                </a>
                <a
                  class="_button_1h2k4_1 _default_1h2k4_42 _text_1h2k4_58 w-100"
                  href="https://chq.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>chq.org</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>


<script lang="ts">
import { onMounted, onBeforeUnmount, reactive, computed } from 'vue';

export default {
  name: 'Nav-Bar',
  setup() {
    const state = reactive({
      isDropdownOpen: false,
      windowWidth: window.innerWidth,
    });

    const toggleDropdown = () => {
      let dropdown = document.getElementById("dropdownMobile") as HTMLDivElement;
      if(state.isDropdownOpen){
        dropdown.style.display = "none";
      }
      else
      {
        dropdown.style.display = "block";
      }
      state.isDropdownOpen = !state.isDropdownOpen;
    };

    const updateWindowWidth = () => {
      state.windowWidth = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener('resize', updateWindowWidth);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWindowWidth);
    });

    return {
      ...state,
      isMobileScreen: computed(() => state.windowWidth < 550),
      toggleDropdown,
    };
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>