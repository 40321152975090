<template>
  <div v-if="isAuthenticated">
    <div
      class=""
      v-for="account in accounts"
      :key="account.localAccountId"
    >
      <div class="card-body">
        <div class="accountIcon " style="margin-bottom: 20px;">
          <p style="margin-bottom: .4rem; color:#afafb3;">Account Details:</p>
          <h5 class="card-title">Name: {{ account.name }}</h5>
          <h6 class="card-subtitle text-body-secondary" style="font-weight: 500">
            Email: {{ account.idTokenClaims!.email! }}
          </h6>
        </div>
        <div class="w-100 " style="display: flex; justify-content: space-evenly; align-items: center;">
          <button class="_button_1h2k4_1 _default_1h2k4_42 _text_1h2k4_5" style="margin-right: 20px;"><RouterLink to="history">View Transaction History</RouterLink></button>
          <button class="_button_1h2k4_1 " style="background-color: #dc3425;" @click="logoutRedirect">Log Out</button>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script setup lang="ts">
import { formatDistance } from 'date-fns'
import { useMsal } from '@/helpers/useMsal'
import { useIsAuthenticated } from '@/helpers/useIsAuthenticated'
import { useCleengStore } from '@/stores/cleeng'
import type { AccountInfo } from '@azure/msal-browser'

const { instance, accounts } = useMsal()
const isAuthenticated = useIsAuthenticated()
const cleengStore = useCleengStore()

const logoutRedirect = () => {
  instance.logoutRedirect()
}

const refreshToken = (account: AccountInfo) => {
  cleengStore.update_tokens()
}

const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000) // Multiply by 1000 to convert from seconds to milliseconds
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  })
}

const countdown = (timestamp: number): string => {
  return formatDistance(new Date(timestamp * 1000), new Date(), { addSuffix: true })
}

const clearCleeng = () => {
  cleengStore.jwt = 'asdfasdf'
}
</script>
