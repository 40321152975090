import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

// Config object to be passed to Msal on creation
const authority =
  import.meta.env.VITE_USE_CLEENG_SANDBOX === 'true'
    ? 'https://my.chq.org/ott/sandbox/'
    : 'https://my.chq.org/ott/'
export const msalConfig = {
  auth: {
    authority,
    knownAuthorities: ['ssochq.b2clogin.com', 'my.chq.org'], // array of domains that are known to be trusted
    clientId: import.meta.env.VITE_SSO_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    redirectUri: import.meta.env.BASE_URL, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: import.meta.env.BASE_URL // Must be registered as a SPA redirectURI on your app registration
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      },
      logLevel: LogLevel.Verbose
    }
  }
}

const instance = new PublicClientApplication(msalConfig)
instance.initialize()
export const msalInstance = instance

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: []
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}
