import axios from 'axios'
import { type Ref } from 'vue'

export const useCleengMediaStoreAPI = (
  cleengJWT: Ref<string>,
  onTokenInvalid: () => Promise<{ jwt: string; refreshToken: string } | void>
) => {
  const instance = axios.create({
    baseURL:
      import.meta.env.VITE_USE_CLEENG_SANDBOX === 'true'
        ? 'https://mediastoreapi-sandbox.cleeng.com/'
        : 'https://mediastoreapi.cleeng.com/',
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 10000
  })

  instance.interceptors.request.use(async (config) => {
    console.debug('Cleeng API request', config)
    if (cleengJWT.value) {
      config.headers['Authorization'] = `Bearer ${cleengJWT.value}`
    } else {
      // Wait for 5 seconds for JWT to be updated
      await new Promise((resolve) => setTimeout(resolve, 5000))
      if (cleengJWT.value) {
        config.headers['Authorization'] = `Bearer ${cleengJWT.value}`
      } else {
        console.error('JWT not found in store after 5 seconds!')
      }
    }
    return config
  })

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.errors.includes('Invalid JWT token')
      ) {
        console.debug('Possibly expired JWT, trying to refresh token', error)

        const refresh = await onTokenInvalid()

        if (!refresh) {
          console.error('Failed to refresh token')
          return Promise.reject(error)
        }

        error.config.headers['Authorization'] = `Bearer ${refresh.jwt}`
        return axios.request(error.config)
      }
      return Promise.reject(error)
    }
  )
  return instance
}
