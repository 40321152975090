<template>
  <main class="centered-content">
    <div class="_panel_1k1su_48 w-100" :style="{ 'max-width': !isAuthenticated ? '1000px' : '' }">
      <form @submit.prevent>
        <div class="_panelHeader_1k1su_65">
          <h3>Assembly Gifting Portal</h3>
        </div>
        <div v-if="isAuthenticated">
          <div class="homePanel" style="">
            <div class="panel_form" style="max-width: 420px">
              <div class="accountIcon _panelHeader_1k1su_65 centered-content">
                <svg
                  class="_icon_23h0x_1"
                  viewBox="0 0 24 24"
                  focusable="false"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"
                    ></path>
                  </g>
                </svg>
                <span class="account_span" style="margin-bottom: -10px; font-weight: 700">Account Information</span>
              </div>
              <div class="_panelHeader_1k1su_65 centered-content">
                <h3>Welcome {{ accounts[0].name }}</h3>
              </div>
              <div class="centered-content">
                <div class="centered-content" style="margin-bottom: 1rem; margin-top:-15px;">
                  <p style="font-size: 25px; font-weight: 700; margin-top: 1rem">Not you?</p>
                  <p>Log out and log back in with your CHQ SSO account.</p>
                </div>  
                <UserInfoCard style="margin-bottom: 1rem; width:90%;" />
                <!-- <RouterLink to="history">History</RouterLink> -->
              </div>
            </div>
            <div style="display: flex; flex-direction: column; margin-left:20px; margin-right:20px;">
              <div class="d-fc panel_form" style="justify-content: space-between">
                <div class="d-fc centered-content w-700">
                  <h1 class="mb-2">Gift an Assembly Membership</h1>
                  <router-link
                    class="_button_1h2k4_1 _default_1h2k4_42 _outlined_1h2k4_50"
                    :to="'gift'"
                    >Continue</router-link
                  >
                </div>
                <div class="Mqg6jb Mhrnjf">
                  <img
                    :src="giftImage"
                    class="T75of arM4bb gift"
                    aria-hidden="true"
                    alt="Icon image"
                    itemprop="image"
                    data-atf="true"
                  />
                </div>
              </div>
              <div class="d-fc panel_form" style="justify-content: space-between">
                <div class="d-fc centered-content w-700">
                  <h1 class="mb-2">Redeem Your Membership Here</h1>
                  <RouterLink class="" to="redeem">
                    <button class="_button_1h2k4_1 _default_1h2k4_42 _outlined_1h2k4_50">
                      I am Ready
                    </button>
                  </RouterLink>
                </div>
                <div class="Mqg6jb Mhrnjf">
                  <img
                    src="https://play-lh.googleusercontent.com/5oBCTgKqRS9Z-KGPyazzwBUrhF7gA804fU4fjYPgECSyNg-HvVklquVheMxAsOv2tA=w240-h480-rw"
                    srcset="
                      https://play-lh.googleusercontent.com/5oBCTgKqRS9Z-KGPyazzwBUrhF7gA804fU4fjYPgECSyNg-HvVklquVheMxAsOv2tA=w480-h960-rw 2x
                    "
                    class="T75of nm4vBd arM4bb gift"
                    aria-hidden="true"
                    alt="Icon image"
                    itemprop="image"
                    data-iml="407.09999990463257"
                    data-atf="true"
                    style="filter: unset"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <p>To purchase a gift membership, you need to log into your CHQ SSO account</p>
          <button
            class="_button_1h2k4_1 _default_1h2k4_42 _outlined_1h2k4_50"
            @click="loginRedirect"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  </main>
</template>

<script setup lang="ts">
import { useMsal } from '@/helpers/useMsal'
import { loginRequest } from '@/helpers/authConfig'
import { onMounted, ref } from 'vue'
import { useIsAuthenticated } from '@/helpers/useIsAuthenticated'

import UserInfoCard from '@/components/UserInfoCard.vue'

import giftImage from '@/assets/gift.png'

const { instance, accounts } = useMsal()
const isAuthenticated = useIsAuthenticated()

onMounted(() => {
  var helpTag = document.querySelector('.help_tag') as HTMLDivElement;

  if (document.body.scrollHeight > window.innerHeight) {
      helpTag.style.position = 'relative';
  } else {
      helpTag.style.position = 'absolute';
  }
});

const loginRedirect = () => {
  instance.loginRedirect(loginRequest)
}
</script>