<template>
  <NavBar style="height: 56px;" />
  <RouterView v-if="canInteract" />
  <div v-else>
    <div class="">
      <div style="display: flex; justify-content: center; align-items: center; height: 99vh">
        <div
          class="spinner-border"
          style="width: 100px !important; height: 100px !important"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  <div class="help_tag">
    <p style="float:right; color:#afafb3; font-size:12px; margin-bottom: 0rem;">For questions about gift purchases, redemptions, or CHQ Assembly memberships, please contact <a href="mailto:assemblysupport@chq.org">assemblysupport@chq.org.</a></p>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { RouterLink, RouterView } from 'vue-router'
import { useMsal } from '@/helpers/useMsal'
import type { InteractionStatus } from '@azure/msal-browser'
import NavBar from '@/components/Navbar.vue'

const { inProgress } = useMsal()

const canInteract = ref<Boolean>(false)

watch(inProgress, (value: InteractionStatus) => {
  canInteract.value = value == 'none'
})


</script>

<style scoped></style>
