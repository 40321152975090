import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/gift',
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'gift',
          component: () => import('@/views/Purchase/FormView.vue')
        },
        {
          path: 'success/:id',
          name: 'purchase-success',
          props: true,
          component: () => import('@/views/Purchase/SuccessView.vue')
        },
        {
          path: 'paypal/success',
          name: 'paypal-success',
          component: () => import('@/views/Purchase/PaypalSuccessView.vue')
        },
        {
          path: 'failed',
          name: 'purchase-failed',
          component: () => import('@/views/Purchase/FailedView.vue')
        }
      ]
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('@/views/HistoryView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/redeem',
      component: () => import('@/views/Redeem/RedeemBase.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'redeem',
          component: () => import('@/views/Redeem/FormView.vue')
        },
        {
          path: 'success',
          name: 'redeem-success',
          component: () => import('@/views/Redeem/SuccessView.vue')
        },
        {
          path: 'failed',
          name: 'redeem-failed',
          component: () => import('@/views/Redeem/FailedView.vue')
        }
      ]
    }
  ]
})

export default router
