import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useCleengMediaStoreAPI } from '@/helpers/cleengAPI'
import { useMsal } from '@/helpers/useMsal'
import jwt_decode from 'jwt-decode'
import type {
  Offer,
  OfferResponse,
  Order,
  CleengPaymentMethod,
  AdyenSession,
  CreateAdyenPaymentResponse,
  CreateAdyenPaymentData,
  AdyenPaymentResponse,
  CleengTransactionHistoryResponse,
  DeliveryDetails,
  CleengGiftResponse,
  VerifyGiftResponse,
  CleengGiftRedemptionResponse,
  CleengPaypalCheckoutResponse
} from '@/types/cleengResponses'
import type { OrderAndOffer } from '@/types/cleeng'
import type { AxiosResponse } from 'axios'
import type { PaymentMethod } from 'node_modules/@adyen/adyen-web/dist/types/types'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { useRouter } from 'vue-router'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

interface DecodedCleengJWT {
  customerId: string
  publisherId: string
  exp: number
}

export const useCleengStore = defineStore('cleeng', () => {
  const jwt = ref('')
  const refreshToken = ref('')
  const customerID = ref('')
  const publisherID = ref('')
  const exp = ref(0)

  const offerIDs = (import.meta.env.VITE_GIFT_OFFERS as string).split(',') || null
  const offers = ref<Record<string, OrderAndOffer>>({})

  const paymentMethods = ref<CleengPaymentMethod[]>([])
  const adyenSession = ref<AdyenSession | null>()

  const { instance: msalInstance, accounts } = useMsal()
  const cleengAPI = useCleengMediaStoreAPI(jwt, update_tokens)
  const ready = ref(false)
  const router = useRouter()

  update_tokens()
  function setup() {
    if (!offerIDs) {
      throw new Error('Gift offers not defined in .env file')
    }

    offerIDs.forEach(async (offerID) => {
      const offer = await get_offer(offerID)
      if (offer.giftable) {
        offers.value[offerID] = {
          order: await create_order(offer.longId, customerID.value),
          offer: offer
        }
      }
    })

    get_payment_methods()
  }

  async function update_tokens(): Promise<{ jwt: string; refreshToken: string } | void> {
    try {
      const silentTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: [],
        account: accounts.value[0]
      })

      if (jwt.value != silentTokenResponse.account.idTokenClaims?.extension_cleengJWT) {
        console.debug('JWT updated in store!')
      }
      jwt.value = silentTokenResponse.account.idTokenClaims?.extension_cleengJWT as string

      if (
        refreshToken.value != silentTokenResponse.account.idTokenClaims?.extension_cleengRefresh
      ) {
        console.debug('Refresh token updated in store!')
      }
      refreshToken.value = silentTokenResponse.account.idTokenClaims
        ?.extension_cleengRefresh as string

      const decoded: DecodedCleengJWT = jwt_decode(jwt.value)
      customerID.value = decoded.customerId
      publisherID.value = decoded.publisherId
      exp.value = decoded.exp

      // This is a potentially bad idea, it seems that MSAL isn't updating the tokens in its own store when it refreshes them silently so we need to do it manually
      accounts.value[0].idTokenClaims = silentTokenResponse.account.idTokenClaims

      if (import.meta.env.VITE_USE_SENTRY == 'true') {
        Sentry.setUser({
          id: accounts.value[0].localAccountId,
          username: accounts.value[0].username,
          email: accounts.value[0].idTokenClaims!.email as string
        })
      }
      ready.value = true

      return { jwt: jwt.value, refreshToken: refreshToken.value }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect({
          scopes: [],
          account: accounts.value[0]
        })
      } else {
        throw error
      }
    }
  }

  async function get_info() {
    const response = await cleengAPI.get('v1/info')
    console.log(response.data)
  }

  async function get_offer(offerID: string): Promise<Offer> {
    const offerResponse = await cleengAPI.get(`v2/offers/${offerID}`)
    return (offerResponse.data as OfferResponse).responseData
  }

  async function create_order(
    offerId: string,
    customerId: string,
    country: string = 'US',
    currency: string = 'USD'
  ): Promise<Order> {
    const response = await cleengAPI.post('orders', {
      offerId,
      customerId,
      country,
      currency,
      buyAsAGift: true
    })
    return response.data.responseData.order
  }

  async function update_order(
    order: Order,
    deliveryDetails: DeliveryDetails,
    paymentMethodId: number | null = null
  ) {
    const response = await cleengAPI.patch(`orders/${order.id}`, {
      buyAsAGift: true,
      deliveryDetails,
      paymentMethodId
    })
  }

  async function get_payment_methods() {
    const response = await cleengAPI.get('payment-methods')
    paymentMethods.value = response.data.responseData.paymentMethods
  }

  async function create_adyen_payment_session(order: Order): Promise<CreateAdyenPaymentData> {
    const response: AxiosResponse<CreateAdyenPaymentResponse> = await cleengAPI.post(
      '/connectors/adyen/sessions',
      {
        orderId: order.id,
        returnUrl: window.location.href
      }
    )

    adyenSession.value = {
      id: response.data.responseData.id,
      sessionData: response.data.responseData.sessionData
    }

    return response.data.responseData
  }

  async function make_adyen_payment(order: Order, paymentMethod: PaymentMethod) {
    // TODO: Handle payment methods that require finalization
    try {
      const response: AxiosResponse<AdyenPaymentResponse> = await cleengAPI.post(
        '/connectors/adyen/initial-payment',
        {
          orderId: order.id,
          returnUrl: window.location.href,
          paymentMethod
        }
      )
      return response.data.responseData
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.errors) {
          router.push({
            name: 'purchase-failed',
            query: { error: error.response.data.errors[0].message }
          })
        }
      }
      router.push({ name: 'purchase-failed' })
    }
  }


  async function get_paypal_checkout_url(order: Order) {
    const successPath = router.resolve({
      name: 'paypal-success'
    }).href
    const failPath = router.resolve({
      name: 'purchase-failed'
    }).href
    const response: AxiosResponse<CleengPaypalCheckoutResponse> = await cleengAPI.post(
      '/connectors/paypal/v1/tokens',
      {
        orderId: order.id,
        successUrl: `${window.location.origin}${successPath}`,
        cancelUrl: window.location.href,
        errorUrl: `${window.location.origin}${failPath}`
      }
    )
    return response.data.responseData.redirectUrl
  }

  async function get_history() {
    if (!customerID.value) {
      throw new Error('Customer ID not set!')
    }
    const response: AxiosResponse<CleengTransactionHistoryResponse> = await cleengAPI.get(
      `customers/${customerID.value}/transactions`
    )
    return response.data.responseData.items
  }

  async function get_gift(giftID: number) {
    const response: AxiosResponse<CleengGiftResponse> = await cleengAPI.get(`gifts/${giftID}`)
    return response.data.responseData
  }

  async function verify_gift_code(code: string) {
    const response: AxiosResponse<VerifyGiftResponse> = await cleengAPI.get(
      `gifts/verification/${code}`
    )
    return response.data.responseData
  }

  async function redeem_gift(code: string) {
    const response: AxiosResponse<CleengGiftRedemptionResponse> = await cleengAPI.post(
      `gifts/redeem/${code}`
    )
    return response.data.responseData
  }

  // watch(accounts, (newVal) => {
  //   if (newVal.length > 0) {
  //     setup()
  //   }
  // })

  // setup()

  return {
    jwt,
    refreshToken,
    customerID,
    exp,
    offerIDs,
    offers,
    setup,
    update_tokens,
    get_info,
    get_offer,
    update_order,
    create_adyen_payment_session,
    make_adyen_payment,
    get_paypal_checkout_url,
    get_history,
    get_gift,
    verify_gift_code,
    redeem_gift,
    adyenSession,
    paymentMethods,
    ready
  }
})
